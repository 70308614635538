import Vue from 'vue'
import VueRouter from 'vue-router'
import PrivateLayout from '../views/Layout/Private/PrivateLayout.vue'
import PublicLayout from '../views/Layout/Public/PublicLayout.vue'
import WebChatLayout from '../views/Layout/WebChatLayout/WebChatLayout.vue'

/* Auth */
const Login = () => import('../views/Pages/Auth/Login/Login.vue')
const PasswordResetPage = () => import('../views/Pages/Auth/PasswordReset/PasswordReset.vue')

/* Home */
const Home = () => import('../views/Pages/Home/Home.vue')

const WhatsappInbox = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/WhatsappInbox/WhatsappInbox.vue')

/* Campaigns */
const CampaignSms = () => import('../views/Pages/Campaigns/CampaignSms/CampaignSms.vue')
const CampaignMailing = () => import('../views/Pages/Campaigns/CampaignMailing/CampaignMailing.vue')
const CampaignVoice = () => import('../views/Pages/Campaigns/CampaignVoice/CampaignVoice.vue')
const CampaignWhatsapp = () => import('../views/Pages/Campaigns/CampaignWhatsapp/CampaignWhatsapp.vue')
const CampaignRcs = () => import('../views/Pages/Campaigns/CampaignRcs/CampaignRcs.vue')

/* Invoices */
const Invoices = () => import('../views/Pages/Invoices/Invoices.vue')

/* Stats */
const VoiceStats = () => import('../views/Pages/Stats/Voice/Voice.vue')
const VoiceCampaignStats = () => import('../views/Pages/Stats/Voice/Campaign.vue')
const ChannelSmsStats = () => import('../views/Pages/Stats/Sms/Channel/ChannelSmsStats.vue')
const CampaignSmsStats = () => import('../views/Pages/Stats/Sms/Campaign/CampaignSmsStats.vue')
const ChannelMailingStats = () => import('../views/Pages/Stats/Mailing/Channel/ChannelMailingStats.vue')
const CampaignMailingStats = () => import('../views/Pages/Stats/Mailing/Campaign/CampaignMailingStats.vue')
const ChannelWhatsappStats = () => import('../views/Pages/Stats/Whatsapp/Channel/ChannelWhatsappStats.vue')
const CampaignWhatsappStats = () => import('../views/Pages/Stats/Whatsapp/Campaign/CampaignWhatsappStats.vue')
const ChannelRcsStats = () => import('../views/Pages/Stats/Rcs/Channel/ChannelRcsStats.vue')
const CertifiedSms = () => import('../views/Pages/Stats/CertifiedSms/CertifiedSms.vue')
const CertifiedEmail = () => import('../views/Pages/Stats/CertifiedEmail/CertifiedEmail.vue')
const AuditStats = () => import('../views/Pages/Stats/Audit/Audit.vue')
const CustomStats = () => import('../views/Pages/Stats/Custom/Custom.vue')

/* Tools */
const DltSms = () => import('../views/Pages/Tools/DLT/DltSms.vue')
const FormsList = () => import('../views/Pages/Tools/Forms/Forms.vue')
const FormsEditor = () => import('../views/Pages/Tools/FormsEditor/FormsEditor.vue')
const TwoWay = () => import('../views/Pages/Tools/TwoWay/TwoWay.vue')
const Unsubscribe = () => import('../views/Pages/Tools/Unsubscribe/Unsubscribe.vue')
const EmailsVerificationTable = () => import('../views/Pages/Tools/EmailsVerification/EmailsVerificationTable/EmailsVerificationTable.vue')
const EmailsVerificationAssistant = () => import('../views/Pages/Tools/EmailsVerification/EmailsVerificationAssistant/EmailsVerificationAssistant.vue')
const IpsVerification = () => import('../views/Pages/Tools/IpsVerification/IpsVerification.vue')
const UrlsStats = () => import('../views/Pages/Tools/Urls/UrlsStats.vue')
const Contacts = () => import('../views/Pages/Tools/Database/Contacts/Contacts.vue')
const ContactsGroups = () => import('../views/Pages/Tools/Database/ContactsGroups/ContactsGroups.vue')
const ImportContacts = () => import('../views/Pages/Tools/Database/ImportContacts/ImportContacts.vue')
const ImportKlaviyo = () => import('../views/Pages/Tools/Database/ImportKlaviyo/ImportKlaviyo.vue')
const FilterDb = () => import('../views/Pages/Tools/Database/FilterDb/FilterDb.vue')
const DdbbValidate = () => import('../views/Pages/Tools/Database/DdbbValidate/DdbbValidate.vue')
const DdbbValidations = () => import('../views/Pages/Tools/Database/DdbbValidate/DdbbValidations.vue')
const MyImports = () => import('../views/Pages/Tools/Database/MyImports/MyImports.vue')
const MySync = () => import('../views/Pages/Tools/Database/MySync/MySync.vue')
const Sync = () => import('../views/Pages/Tools/Database/Sync/Sync.vue')

/* Help */
const Support = () => import('../views/Pages/Help/Suport/Suport.vue')
const Faq = () => import('../views/Pages/Help/Faq/Faq.vue')
const Rgpd = () => import('../views/Pages/Help/Rgpd/Rgpd.vue')
const VideoTutorials = () => import('../views/Pages/Help/VideoTutorials/VideoTutorials.vue')

/* Developers */
const GoogleVerified = () => import('../views/Pages/Developers/GoogleVerified/GoogleVerified.vue')
const GoogleVerifiedRequest = () => import('../views/Pages/Developers/GoogleVerified/GoogleVerifiedRequest/GoogleVerifiedRequest.vue')
const ChangeApiPassword = () => import('../views/Pages/Developers/ChangeApiPassword/ChangeApiPassword.vue')
const ChangeSmppPassword = () => import('../views/Pages/Developers/ChangeSmppPassword/ChangeSmppPassword.vue')

const WhatsAppChannel = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/WhatsAppChannel.vue')
const Assistant = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Assistant/Assistant.vue')
const Wabas = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Wabas/Wabas.vue')
const WabaPhoneDetail = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Wabas/WabaPhoneDetail.vue')


const PhoneProfileInfo = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/PhoneProfileInfo/PhoneProfileInfo.vue')
const InboxConfig = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/InboxConfig/InboxConfig.vue')
const SpentInbox = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/SpentInbox/SpentInbox.vue')
const WhatsappMyLine = () => import('../views/Pages/Developers/ChannelIntegration/WhatsAppChannel/MyLine/MyLine.vue')

/* Payments */
const PaymentPage = () => import('../views/Pages/Payment/AddPayment/AddPayment.vue')

/* Users */
const Profile = () => import('../views/Pages/Users/Profile/Profile.vue')
const BillingPage = () => import('../views/Pages/Users/Billing/Billing.vue')
const SubscriptionPlans = () => import('../views/Pages/Users/SubscriptionPlans/SubscriptionPlans.vue')
const Balance = () => import('../views/Pages/Users/Balance/Balance.vue')
const PlanAndPrice = () => import('../views/Pages/Users/PlansAndPrices/PlanAndPrice.vue')
const ChangePassword = () => import('../views/Pages/Users/ChangePassword/ChangePassword.vue')
const BillingProfile = () => import('../views/Pages/Users/BillingProfile/BillingProfile.vue')
const ContactData = () => import('../views/Pages/Users/ContactData/ContactData.vue')
const SupplementaryData = () => import('../views/Pages/Users/SupplementaryData/SupplementaryData.vue')
const PageProfileRcsList = () => import('../views/Pages/Users/ProfileRCS/ProfileRCSList.vue')
const PageProfileRcs = () => import('../views/Pages/Users/ProfileRCS/ProfileRCS.vue')

/* Templates */
const VoiceTemplatePage = () => import('../views/Pages/Templates/Voice.vue')
const CreateLandingTemplate = () => import('../views/Pages/Templates/Landing/CreateLandingTemplate.vue')
const CreateWhatsappTemplate = () => import('../views/Pages/Templates/Whatsapp/CreateWhatsappTemplate.vue')

/* Teams */
const TeamPage = () => import('../views/Pages/Team/Team.vue')

/* Releases */
const ReleasesPage = () => import('../views/Pages/Releases/ReleasesPage.vue')

/* Share */
const shareCampaignSmsStats = () => import('../views/Pages/Share/Stats/Sms/Campaign/CampaignSmsStats.vue')
const shareCampaignMailingStats = () => import('../views/Pages/Share/Stats/Mailing/Campaign/CampaignMailingStats.vue')
const shareCampaignVoiceStats = () => import('../views/Pages/Share/Stats/Voice/Campaign/Campaign.vue')

/* Integrations */
const PluginHubspot = () => import('../views/Pages/Integrations/Hubspot/PluginHubspot.vue')
const PluginSalesforce = () => import('../views/Pages/Integrations/SalesForce/PluginSalesForce.vue')
const PluginZapier = () => import('../views/Pages/Integrations/Zapier/PluginZapier.vue')
const PluginSalesManago = () => import('../views/Pages/Integrations/SalesManago/PluginSalesManago.vue')
const PluginMagento = () => import('../views/Pages/Integrations/Magento/PluginMagento.vue')
const PluginKlaviyo = () => import('../views/Pages/Integrations/Klaviyo/PluginKlaviyo.vue')
const WooCommerce = () => import('../views/Pages/Integrations/WooCommerce/PluginWooCommerce.vue')
const PluginModuleIntegration = () => import('../views/Pages/Integrations/PluginModuleIntegrations/PluginModuleIntegration.vue')

const WhatsappConfigInboxAuto = () => import('../views/Pages/Design/WhatsappConfigInboxAuto.vue')
const BandejaEntradaCompleta = () => import('../views/Pages/Design/BandejaEntradaCompleta.vue')

const SubscriptionNeeded = () => import('../views/Pages/Plans/SubscriptionNeeded/SubscriptionNeeded.vue')
const WhatsappSubscriptionNeeded = () => import('../views/Pages/Plans/SubscriptionNeeded/WhatsappSubscriptionNeeded.vue')
const ChatBotsSubscriptionNeeded = () => import('../views/Pages/Plans/SubscriptionNeeded/ChatBotsSubscriptionNeeded.vue')

const RetrySubscriptionRenewPayment = () => import('../views/Pages/Plans/SubscriptionNeeded/RetrySubscriptionRenewPayment.vue')
const SubscriptionUpdateCardNeeded = () => import('../views/Pages/Plans/SubscriptionUpdateCard/SubscriptionUpdateCardNeeded.vue')
const PlanSubscriptionUpdateCardNeeded = () => import('../views/Pages/Plans/SubscriptionUpdateCard/PlanSubscriptionUpdateCardNeeded.vue')

const WebChat = () => import('../components/WebChat/WebChat.vue')
const WebChatInbox = () => import('../components/WebChat/Inbox/Inbox.vue')
const Chatbots = () => import('../views/Pages/Chatbots/Chatbots.vue')
const ChatbotsTasks = () => import('../views/Pages/Chatbots/Tasks/Tasks.vue')

const RcsInbox = () => import('../components/Rcs/Inbox/Inbox.vue')

Vue.use(VueRouter)

const routes = [

  /* Auth */
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/password/reset/:token',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
  },

  /* Home */
  {
    path: '/',
    name: 'Home',
    redirect: '/home',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
      tab: 'sms',
    },
    children: [
      {
        path: 'home',
        name: 'HomePage',
        component: Home,
        meta: {},
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: Invoices,
      },
    ],
  },
  /* Inbox */
  {
    path: '/inbox',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'whatsapp',
        name: 'WhatsappInboxPage',
        component: WhatsappInbox,
        meta: {
          groupName: 'Inbox',
          tab: 'whatsapp',
        },
      },
      /* Web chat private */
      {
        path: 'web-chat',
        name: 'web-chat',
        component: WebChatInbox,
        meta: {
          groupName: 'WebChat',
        },
      },
      {
        path: 'rcs',
        name: 'rcs',
        component: RcsInbox,
        meta: {
          groupName: 'rcs',
          tab: 'rcs',
        },
      },
    ],
  },
  /* Releases */
  {
    path: '/releases',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'view',
        name: 'releasesPage',
        component: ReleasesPage,
        meta: {
          groupName: 'Releases',
          tab: 'help',
        },
      },
    ],
  },

  /* Campains */
  {
    path: '/campaign',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'sms/create',
        name: 'smsCreate',
        component: CampaignSms,
        meta: {
          groupName: 'Campaigns',
          tab: 'sms',
          permissions: {
            permission: 'view-campaigns',
          },
        },
      },
      {
        path: 'sms/edit/:id',
        name: 'smsEdit',
        component: CampaignSms,
        meta: {
          groupName: 'Campaigns',
          tab: 'sms',
          permissions: {
            permission: 'edit-campaigns',
          },
        },
      },
      {
        path: 'mailing/create',
        name: 'mailingCreate',
        component: CampaignMailing,
        meta: {
            groupName: 'Campaigns',
            tab: 'mail',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'mailing/edit/:id',
        name: 'mailingEdit',
        component: CampaignMailing,
        meta: {
            groupName: 'Campaigns',
            tab: 'mail',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'voice/create',
        name: 'voiceCreate',
        component: CampaignVoice,
        meta: {
            groupName: 'Campaigns',
            tab: 'voz',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'voice/edit/:id',
        name: 'voiceEdit',
        component: CampaignVoice,
        meta: {
            groupName: 'Campaigns',
            tab: 'voz',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'whatsapp/create',
        name: 'CampaignWhatsappCreate',
        component: CampaignWhatsapp,
        meta: {
            groupName: 'Campaigns',
            tab: 'whatsapp',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'whatsapp/edit/:id',
        name: 'whatsappEdit',
        component: CampaignWhatsapp,
        meta: {
            groupName: 'Campaigns',
            tab: 'whatsapp',
            permissions: {
              permission: 'view-campaigns',
            },
        },
      },
      {
        path: 'rcs/create',
        name: 'rcsCreate',
        component: CampaignRcs,
        meta: {
          groupName: 'Campaigns',
          tab: 'rcs',
        },
      },
      {
        path: 'rcs/edit/:id',
        name: 'rcsEdit',
        component: CampaignRcs,
        meta: {
          groupName: 'Campaigns',
          tab: 'sms',
        },
      },
    ],
  },

  /* Stats */
  {
    path: '/stats',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'voice',
        name: 'voiceStats',
        component: VoiceStats,
        meta: {
            groupName: 'Stats',
            tab: 'voz',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'voice/:type/campaign/:id',
        name: 'voiceCampaignStats',
        component: VoiceCampaignStats,
        meta: {
            groupName: 'Stats',
            tab: 'voz',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'sms',
        name: 'ChannelSmsStats',
        component: ChannelSmsStats,
        meta: {
            groupName: 'Stats',
            tab: 'sms',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'sms/campaign/:id',
        name: 'CampaignSmsStats',
        component: CampaignSmsStats,
        meta: {
            groupName: 'Stats',
            tab: 'sms',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'mailing',
        name: 'ChannelMailingStats',
        component: ChannelMailingStats,
        meta: {
            groupName: 'Stats',
            tab: 'mail',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'mailing/campaign/:id',
        name: 'CampaignMailingStats',
        component: CampaignMailingStats,
        meta: {
            groupName: 'Stats',
            tab: 'mail',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'whatsapp',
        name: 'ChannelWhatsappStats',
        component: ChannelWhatsappStats,
        meta: {
            groupName: 'Stats',
            tab: 'whatsapp',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'whatsapp/campaign/:id',
        name: 'CampaignWhatsappStats',
        component: CampaignWhatsappStats,
        meta: {
            groupName: 'Stats',
            tab: 'whatsapp',
            permissions: {
              permission: 'view-stats',
            },
        },
      },
      {
        path: 'rcs',
        name: 'ChannelRcsStats',
        component: ChannelRcsStats,
        meta: {
            groupName: 'Stats',
            tab: 'rcs',
        },
      },
      {
        path: 'rcs/campaign/:id',
        name: 'CampaignRcsStats',
        component: CampaignWhatsappStats,
        meta: {
            groupName: 'Stats',
            tab: 'rcs',
        },
      },
      {
        path: 'certified-sms',
        name: 'certifiedSms',
        component: CertifiedSms,
        meta: {
            groupName: 'Stats',
            tab: 'sms',
            permissions: {
              permission: 'view-certificates',
            },
        },
      },
      {
        path: 'certified-email',
        name: 'certifiedEmail',
        component: CertifiedEmail,
        meta: {
            groupName: 'Stats',
            tab: 'mail',
            permissions: {
              permission: 'view-certificates',
            },
        },
      },
      {
        path: 'audit',
        name: 'auditStatsPage',
        component: AuditStats,
        meta: {
            groupName: 'Stats',
            tab: 'sms',
            permissions: {
              permission: 'view-audit',
            },
        },
      },
      {
        path: 'custom',
        name: 'customStatsPage',
        component: CustomStats,
        meta: {
            groupName: 'Stats',
            tab: 'sms',
            permissions: {
              permission: 'view-reports',
            },
        },
      },
    ],
  },

  /* Tools */
  {
    path: '/tools',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dlt-sms',
        name: 'DltSms',
        component: DltSms,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'forms',
        name: 'allForms',
        component: FormsList,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'forms/editor',
        name: 'formsEditor',
        component: FormsEditor,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: '2way/inbox',
        name: 'TwoWay',
        component: TwoWay,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'unsubscribe/editor',
        name: 'Unsubscribe',
        component: Unsubscribe,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'view-unsubscription-pages',
          },
        },
      },
      {
        path: 'emails',
        name: 'emails',
        component: EmailsVerificationTable,
        meta: {
          groupName: 'Tools',
          tab: 'mail',
        },
      },
      {
        path: 'emails/assistant',
        name: 'emails-assistant',
        component: EmailsVerificationAssistant,
        meta: {
          groupName: 'Tools',
          tab: 'mail',
        },
      },
      {
        path: 'account/ips',
        name: 'ips',
        component: IpsVerification,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'user/urls',
        name: 'urlsStats',
        component: UrlsStats,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'view-tracking-urls',
          },
        },
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'view-contacts',
          },
        },
      },
      {
        path: 'groups',
        name: 'ContactsGroups',
        component: ContactsGroups,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'view-contacts',
          },
        },
      },
      {
        path: 'importcontacts/:type/:id?',
        name: 'ImportContacts',
        component: ImportContacts,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'import-klaviyo/:type/:id?',
        name: 'ImportKlaviyo',
        component: ImportKlaviyo,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'filterdb',
        name: 'FilterDb',
        component: FilterDb,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'ddbbvalidate',
        name: 'DdbbValidate',
        component: DdbbValidate,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'edit-email-validations',
          },
        },
      },
      {
        path: 'ddbbvalidations',
        name: 'Ddbbvalidations',
        component: DdbbValidations,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
          permissions: {
            permission: 'view-email-validations',
          },
        },
      },
      {
        path: 'myimports',
        name: 'MyImports',
        component: MyImports,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'mysync',
        name: 'MySync',
        component: MySync,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
      {
        path: 'sync',
        name: 'Sync',
        component: Sync,
        meta: {
          groupName: 'Tools',
          tab: 'sms',
        },
      },
    ],
  },

  /* Help */
  {
    path: '/help',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'support',
        name: 'support',
        component: Support,
        meta: {
          groupName: 'Support',
          tab: 'help',
        },
      },
      {
        path: 'faq',
        name: 'faq',
        component: Faq,
        meta: {
          groupName: 'Faq',
          tab: 'help',
        },
      },
      {
        path: 'rgpd/faq',
        name: 'Rgpd',
        component: Rgpd,
        meta: {
          groupName: 'Rgpd',
          tab: 'help',
        },
      },
      {
        path: 'video-tutorials',
        name: 'VideoTutorials',
        component: VideoTutorials,
        meta: {
          groupName: 'VideoTutorials',
          tab: 'help',
        },
      },
    ],
  },

  /* Design */
  {
    path: '/design',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'bandeja-entrada-completa',
        name: 'BandejaEntradaCompleta',
        component: BandejaEntradaCompleta,
        meta: {
          groupName: 'Developers',
        },
      },
    ],
  },

  /* Developers */
  {
    path: '/developers',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'whatsapp-configuracion-bandeja-automatico',
        name: 'WhatsappConfigInboxAuto',
        component: WhatsappConfigInboxAuto,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'verified',
        name: 'GoogleVerified',
        component: GoogleVerified,
        meta: {
          groupName: 'Developers',
          tab: 'sms',
        },
      },
      {
        path: 'verified/request/:id?',
        name: 'GoogleVerifiedRequest',
        component: GoogleVerifiedRequest,
        meta: {
          groupName: 'Developers',
          tab: 'sms',
        },
      },
      {
        path: 'change-api-password',
        name: 'profile-change-api-password',
        component: ChangeApiPassword,
        meta: {
          groupName: 'Developers',
          tab: 'apis',
          permissions: {
            permission: 'view-certificates',
          },
        },
      },
      {
        path: 'change-smpp-password',
        name: 'profile-change-smpp-password',
        component: ChangeSmppPassword,
        meta: {
          groupName: 'Developers',
          tab: 'apis',
          permissions: {
            permission: 'view-certificates',
          },
        },
      },
      {
        path: 'whatsapp-channel',
        name: 'WhatsAppChannelPage',
        component: WhatsAppChannel,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'whatsapp-assistant',
        name: 'AssistantPage',
        component: Assistant,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'wabas',
        name: 'WabasPage',
        component: Wabas,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
          permissions: {
            permission: 'view-wabas',
          },
        },
      },
      {
        path: 'waba-phone-detail',
        name: 'WabaPhoneDetailPage',
        component: WabaPhoneDetail,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'phone-profile',
        name: 'PhoneProfileInfoPage',
        component: PhoneProfileInfo,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'inbox-config',
        name: 'InboxConfigPage',
        component: InboxConfig,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
      {
        path: 'spent-inbox',
        name: 'SpentInboxPage',
        component: SpentInbox,
        meta: {
          groupName: 'Developers',
          tab: 'whatsapp',
        },
      },
    ],
  },

  /* Payments */
  {
    path: '/payments',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'addPayment',
        component: PaymentPage,
        meta: {
          groupName: 'PaymentPage',
          tab: 'sms',
          permissions: {
            permission: 'create-payments',
          },
        },
      },
      {
        path: 'subscription-needed',
        name: 'SubscriptionNeeded',
        component: SubscriptionNeeded,
        meta: {
          groupName: 'PaymentPage',
          tab: 'whatsapp',
        },
      },
      {
        path: 'whatsapp-subscription-needed',
        name: 'WhatsappSubscriptionNeeded',
        component: WhatsappSubscriptionNeeded,
        meta: {
          groupName: 'PaymentPage',
          tab: 'whatsapp',
        },
      },
      {
        path: 'chat-bots-subscription-needed',
        name: 'ChatBotsSubscriptionNeeded',
        component: ChatBotsSubscriptionNeeded,
        meta: {
          groupName: 'PaymentPage',
          tab: 'chatbots',
        },
      },
      {
        path: 'retry-payment',
        name: 'RetrySubscriptionRenewPayment',
        component: RetrySubscriptionRenewPayment,
        meta: {
          groupName: 'PaymentPage',
          tab: 'whatsapp',
        },
      },
      {
        path: 'subscription-update-card-needed',
        name: 'SubscriptionUpdateCardNeeded',
        component: SubscriptionUpdateCardNeeded,
        meta: {
          groupName: 'PaymentPage',
          tab: 'whatsapp',
        },
      },
      {
        path: 'plan-subscription-update-card-needed',
        name: 'PlanSubscriptionUpdateCardNeeded',
        component: PlanSubscriptionUpdateCardNeeded,
        meta: {
          groupName: 'PaymentPage',
          tab: 'whatsapp',
        },
      },
    ],
  },

  /* Users */
  {
    path: '/users',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          groupName: 'Users',
          tab: 'sms',
        },
      },
      {
        path: 'billing',
        name: 'billing',
        component: BillingPage,
        meta: {
          groupName: 'Users',
          tab: 'sms',
          permissions: {
            permission: 'view-invoices',
          },
        },
      },
      {
        path: 'plans',
        name: 'plans',
        component: SubscriptionPlans,
        meta: {
          groupName: 'Users',
          tab: 'sms',
          permissions: {
            permission: 'view-plans',
          },
        },
      },
      {
        path: 'balance',
        name: 'balance',
        component: Balance,
        meta: {
          groupName: 'Users',
          tab: 'sms',
          permissions: {
            permission: 'view-balance-alerts',
          },
        },
      },
      {
        path: 'plans-and-prices',
        name: 'plansAndPrice',
        component: PlanAndPrice,
        meta: {
          groupName: 'Users',
          tab: 'sms',
        },
      },
      {
        path: 'change-password',
        name: 'profile-change-password',
        component: ChangePassword,
        meta: {
          groupName: 'Users',
          tab: 'sms',
        },
      },
      {
        path: 'billing-profile',
        name: 'billingProfile',
        component: BillingProfile,
        meta: {
          groupName: 'Users',
          tab: 'sms',
          permissions: {
            permission: 'view-billing-profile',
          },
        },
      },
      {
        path: 'contact-data',
        name: 'contact-data',
        component: ContactData,
        meta: {
          groupName: 'Users',
          tab: 'sms',
        },
      },
      {
        path: 'supplementary-data',
        name: 'supplementary-data',
        component: SupplementaryData,
        meta: {
          groupName: 'Users',
          tab: 'sms',
          permissions: {
            permission: 'view-nia-profile',
          },
        },
      },
      {
        path: 'profile-rcs',
        name: 'profile-rcs',
        component: PageProfileRcsList,
        meta: {
          groupName: 'Users',
        },
      },
      {
        path: 'profile-rcs/create',
        name: 'profile-rcs',
        component: PageProfileRcs,
        meta: {
          groupName: 'Users',
        },
      },
    ],
  },

  /* Templates */
  {
    path: '/template',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'voice',
        name: 'voiceTemplates',
        component: VoiceTemplatePage,
        meta: {
          groupName: 'Templates',
          tab: 'voz',
        },
      },
      {
        path: 'landing/create',
        name: 'landingEditor',
        component: CreateLandingTemplate,
        meta: {
          groupName: 'Templates',
          tab: 'sms',
        },
      },
      {
        path: 'whatsapp/create',
        name: 'WhatsappEditor',
        component: CreateWhatsappTemplate,
        meta: {
          groupName: 'Templates',
          tab: 'whatsapp',
        },
      },
    ],
  },

  /* Teams */
  {
    path: '/teams',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'teamPage',
        component: TeamPage,
        meta: {
          groupName: 'Teams',
          tab: 'teams',
          permissions: {
            permission: 'view-teams',
          },
        },
      },
    ],
  },

  // /* Channels */
  {
    path: '/channels',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'whatsapp/my-line',
        name: 'MyLinePage',
        component: WhatsappMyLine,
        meta: {
          groupName: 'Channels',
          tab: 'whatsapp',
        },
      },
    ],
  },

  /* Integraciones */
  {
    path: '/integrations',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'plugin-and-integrations',
        name: 'pluginModuleIntegrationPage',
        component: PluginModuleIntegration,
        meta: {
          groupName: 'Developers',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'hubspot',
        name: 'PluginHubspot',
        component: PluginHubspot,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'salesforce',
        name: 'PluginSalesforce',
        component: PluginSalesforce,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'zapier',
        name: 'PluginZapier',
        component: PluginZapier,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'salesmanago',
        name: 'PluginSalesManago',
        component: PluginSalesManago,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'magento',
        name: 'PluginMagento',
        component: PluginMagento,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'klaviyo',
        name: 'PluginKlaviyo',
        component: PluginKlaviyo,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
      {
        path: 'woocommerce',
        name: 'WooCommerce',
        component: WooCommerce,
        meta: {
          groupName: 'Integrations',
          tab: 'integrations',
          permissions: {
            permission: 'view-integrations',
          },
        },
      },
    ],
  },

  /* Share */
  {
    path: '/share',
    name: 'shareStats',
    component: PublicLayout,
    children: [
      {
        path: 'stats/sms/campaign/:id/:shareHash',
        name: 'shareSmsStats',
        component: shareCampaignSmsStats,
        meta: {
          groupName: 'Share',
          tab: 'sms',
        },
      },
      {
        path: 'stats/mailing/campaign/:id/:shareHash',
        name: 'shareMailingStats',
        component: shareCampaignMailingStats,
        meta: {
          groupName: 'Share',
          tab: 'mail',
        },
      },
      {
        path: 'stats/voice/:type/campaign/:id/:shareHash',
        name: 'shareVoiceStats',
        component: shareCampaignVoiceStats,
        meta: {
          groupName: 'Share',
          tab: 'voz',
        },
      },
    ],
  },

  /* Web chat */
  {
    path: '/web-chat',
    name: 'webChat',
    component: WebChatLayout,
    children: [
      {
        path: 'chat',
        name: 'chat',
        component: WebChat,
        meta: {
          groupName: 'WebChat',
        },
      },
    ],
  },

  // chatbots
  {
    path: '/chatbots',
    name: 'chatbots',
    component: PrivateLayout,
    meta: {
      requiresAuth: true,
      tab: 'chatbots',
    },
    children: [
      {
        path: 'list',
        name: 'list',
        component: Chatbots,
        meta: {
          groupName: 'chatbots',
        },
      },
       {
        path: 'tasks',
        name: 'tasks',
        component: ChatbotsTasks,
        meta: {
          groupName: 'chatbots',
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // if (to.hash) {
    //   return {
    //     selector: to.hash,
    //   }
    // }
    window.scrollTo(0, 0)
  },
})

export default router
